import React, { Component } from 'react';
import './Footer.css';
import { lang } from '../../../il8n/lang';
import { Link } from 'react-router-dom';
import logoSM from './Images/logo-sm.png';
import { toggleInterstitial } from '../../../Actions/Index';
import { connect } from 'react-redux';

/**
 * The main application footer.
 * @constructor
 */

class Footer extends Component {
  /**
  * Returns the current year.
  */
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
    <footer className='footer'>
      <div className="wrap">
      <a className='footer__logo' href='http://www.spinmaster.com/' target='_blank' rel='noopener noreferrer'>
        <img src={logoSM} alt={lang.footer.brand} />
      </a>
      <div className='footer__links'>
      <a href={lang.footer.contactLink} target="_blank">
          {lang.footer.contactUs}
          </a>
          <a href={lang.footer.privacyLink} target="_blank">
          {lang.footer.privacy}
        </a>
          <a href={lang.footer.termsLink} target="_blank">
          {lang.footer.terms}
        </a>
          <a href={lang.footer.supportLink} target="_blank">
          {lang.footer.support}
        </a>
      </div>
      <div className='footer__copyright'>
        {`© ${ this.getYear() } ${ lang.footer.copy }`}
      </div>
      </div>
    </footer>
    );
  }
}

export default Footer;
