// Core
import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { lang } from './il8n/lang';
import { siteMapByLocale } from './const';

// Analytics
import ReactGA from 'react-ga';
import {UA} from './const';
import TagManager from 'react-gtm-module'

// Redux
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { PersistGate } from 'redux-persist/lib/integration/react';

// Page Wrappers
import App from './App/App';
import AsyncComponent from './components/Async/AsyncComponent';

// Global Assets
/*eslint-disable no-unused-vars*/
/*eslint-enable no-unused-vars*/

// Global CSS
import './index.css';

// Localized Overide CSS
import './localeOverrides/en_us.css';

// Pages
const Home = AsyncComponent(() => import('./components/Pages/Home/Home'));
const Downloads = AsyncComponent(() => import('./components/Pages/Downloads/Downloads'));
const Events = AsyncComponent(() => import('./components/Pages/Events/Events'));

const Toys = AsyncComponent(() => import('./components/Pages/Toys/Toys'));
const Toy = AsyncComponent(() => import('./components/Pages/Toy/Toy'));
const NoMatch = AsyncComponent(() => import('./components/Pages/NoMatch/NoMatch'));

const WhereToBuy = AsyncComponent(() => import('./components/Pages/WhereToBuy/WhereToBuy'));
const Collection = AsyncComponent(() => import('./components/Pages/Collection/Collection'));
const HTMLContent = AsyncComponent(() => import('./components/Pages/HTMLContent/HTMLContent'));

const createBrowserHistory = require("history").createBrowserHistory;

// Create the Store
let { store, persistor } = configureStore();

// Analytics
ReactGA.initialize(UA);
function logPageView(){
  window.scrollTo(0, 0); // scroll page to top
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

// Analytics - GTM

const tagManagerArgs =

{   gtmId: 'GTM-PWH56GT' }
TagManager.initialize(tagManagerArgs)

// Set Lang on Load (Seems Messy)
const pathLang = window.location.pathname.split('/')[1];
if(pathLang !== lang.getLanguage() && pathLang){
  if (pathLang in siteMapByLocale){
    lang.setLanguage(pathLang);
  } else {
    lang.setLanguage('en_us');
  }
} else if(!pathLang){
  window.location.replace('/en_us');
}


const history = createBrowserHistory();

history.listen(function (location) {
  logPageView();
});

logPageView();

// Define Routes
const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/:locale",
    exact: true,
    component: Home,
  },
  {
    path: "/:locale/events",
    exact: false,
    component: Events,
  },
  {
    path: "/:locale/downloads",
    exact: false,
    component: Downloads,
  },
  {
    path: "/:locale/toys/:pid",
    exact: false,
    component: Toy,
  },
  {
    path: "/:locale/toys",
    exact: true,
    component: Toys,
  },
  // Commenting out this route as requested until it is requested to bring it back up
  // {
  //   path: "/:locale/collection",
  //   exact: true,
  //   component: Collection,
  // },
  {
    path: "/:locale/where-to-buy",
    exact: true,
    component: WhereToBuy,
  },
  {
    path: "",
    exact: false,
    component: NoMatch,
  },
];

ReactDOM.render(
  <Provider store={ store }>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter onUpdate={logPageView}>
        <div>
          <App>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={ index }
                  path={ route.path }
                  exact={ route.exact }
                  component={ route.component }
                />
              ))}
            </Switch>
          </App>
        </div>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
