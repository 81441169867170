import React, { Component } from 'react';
import { lang } from '../il8n/lang';
import './App.css';

// Common Components
import MainMenu from '../components/Navs/MainMenu/MainMenu';
import Footer from '../components/Navs/Footer/Footer';
import Interstitial from '../components/Interstitial/Interstitial';

// Setting lang on HTML
import Helmet from 'react-helmet';

/**
 * Main App Wrapper - This class wraps the entire application. It handles the proper assignment of the localized subdir on mount. It is also home to any common components.
 * @constructor
 */
class App extends Component {

  render() {
    return (
      <div>
        <Helmet>
          <html lang={lang.getLanguage()} />
        </Helmet>
        <Interstitial />
        <MainMenu collapse={true} centered={false} />
        <div className="content">
          { this.props.children }
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
