export const CHANGE_LANG = 'CHANGE_LANG';
export const CHANGE_ACTIVEVID = 'CHANGE_ACTIVEVID';
export const CHANGE_PRODUCTS = 'CHANGE_PRODUCTS';
export const CHANGE_PRODUCT = 'CHANGE_PRODUCT';
export const CHANGE_COLLECTIBLES = 'CHANGE_COLLECTIBLES';
export const TOGGLE_INTERSTITIAL = 'TOGGLE_INTERSTITIAL';
export const CHANGE_HAVEIT = 'CHANGE_HAVEIT';
export const CHANGE_WANTIT = 'CHANGE_WANTIT';
export const CHANGE_LIKEIT = 'CHANGE_LIKEIT';
export const CHANGE_FLIP = 'CHANGE_FLIP';

export function changeLanguage(lang) {
  return {
    type: CHANGE_LANG,
    lang
  }
}

export function changeActiveVid(activeVid) {
  return {
    type: CHANGE_ACTIVEVID,
    activeVid
  }
}

export function changeProducts(products) {
  return {
    type: CHANGE_PRODUCTS,
    products
  }
}

export function changeProduct(product) {
  return {
    type: CHANGE_PRODUCT,
    product
  }
}

// export function changeCollection(collection) {
//   return {
//     type: CHANGE_COLLECTION,
//     collection
//   }
// }
export function changeFlip(flipIt) {
  return {
    type: CHANGE_FLIP,
    flipIt
  }
}

export function changeCollectibles(collectibles) {
  console.log('DISPATCH', collectibles)
  return {
    type: CHANGE_COLLECTIBLES,
    collectibles
  }
}

export function changeHaveIt(haveIt) {
  return {
    type: CHANGE_HAVEIT,
    haveIt
  }
}

export function changeWantIt(wantIt) {
  return {
    type: CHANGE_WANTIT,
    wantIt
  }
}

export function changeLikeIt(likeIt) {
  return {
    type: CHANGE_LIKEIT,
    likeIt
  }
}


export function toggleInterstitial(active, url, label, isIframe) {
  return {
    type: TOGGLE_INTERSTITIAL,
    interstitial: {
      active,
      url,
      label,
      isIframe
    }
  }
}
