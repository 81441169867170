import {cdnBase} from '../../const';

export default {
  nl_nl: {
    langKeys: {
      en_us: 'Engels (US)',
      mx_mx: 'Mexico'
    },
    interstitial: {
      heading: 'Deze site verlaten',
      copy: 'Door op "Doorgaan" te klikken verlaat je deze website en kom je terecht op een winkelsite. Vergeet niet dat ze verschillende voorwaarden en privacybeleid hebben. Hopelijk zien we je snel weer! KIDS, vraag toestemming aan je ouders voordat je andere sites bezoekt en deel NOOIT persoonlijke informatie over jezelf - inclusief je volledige naam, adres en telefoonnummer, en laat je ouder of voogd elke transactie afhandelen.',
      continue: 'Doorgaan',
      cancel: 'Annuleren'
    },
    brand: 'Tech Deck',
    applinks: {
      apple: 'LINK NAAR APPLE-APP', 
      google: 'LINK NAAR GOOGLE-APP'
    },
    chooseRegion: 'Kies taal',
    loading: 'Laden',
    select: 'Selecteer',
    clearAll: 'Wis alles',
    filters: 'Filters',
    details: 'Details',
    warnings: {
      enableIframes: 'Je moet iFrames inschakelen om deze video te bekijke'
    },
    helmet: {
      siteTitle: ' | Tech Deck '
    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/toys',
          display: 'Producten'
        },
        // It has been requested for this menu item to be removed until an update for the collections page is provided at a later date
        // {
        //   type: 'internal',
        //   href: '/collection',
        //   display: 'Collection'
        // },
        {
          type: 'external',
          href: 'https://www.youtube.com/user/TechDeckVideos',
          display: 'Videos'
        },
        {
          type: 'internal',
          href: '/downloads',
          display: 'Downloads'
        },
        {
          type: 'internal',
          href: '/events',
          display: 'Evenementen'
        },
        {
          type: 'internal_hash',
          href: '/#social',
          display: 'Social'
        },
        {
          type: 'internal_hash',
          href: '/#wheretobuy',
          display: 'Waar te koop'
        },
        {
          type: 'external',
          href: 'https://spinmastersupport.helpshift.com/a/tech-deck/?p=web',
          display: 'Ondersteuning'
        }
      ],
      logo: "https://techdeck2019.s3.amazonaws.com/images/logos/td_logo_updated.png"
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Neem contact met ons op",
      privacy: 'Privacybeleid',
      terms: 'Servicevoorwaarden',
      support: 'Ondersteuning',
      copy: 'Spin Master Ltd. Alle rechten voorbehouden.',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us'
    },
    home: {
      title: 'Home',
      whereToBuy: 'Waar te koop',
      sections: {
        collection:{
          heading: "",
          subheading:[ "Collectie","Series One"],
          button: "Volledige collectie bekijken "
        },
        smallbutton:[
          {
            class:'academy',
            bg:{
              type: 'vid',
              src: `${cdnBase}videos/academy.mp4`,
              mobilesrc: `${cdnBase}images/banners/holder.png`
            },
            heading: 'Tech Deck',
            headingbig: 'Academy',
            copy: 'Leer van onze Tech Deck Masters hoe je coole fingerboard trucjes kunt doen  ',
            link: '/nl_nl/videos'
          },
          {
            class:'collection',
            bg:{
              type: 'vid',
              src: `${cdnBase}videos/hallof.mp4`,
              mobilesrc: `${cdnBase}images/banners/holder.png`
            },
            heading: 'Bouw je',
            headingbig: 'Collectie',
            copy: 'Bouw je Tech Deck-collectie! Begin klein, ga groots! ',
            link: '/nl_nl/toys'
          },
          {
            class:'community',
            bg:{
              type: 'img',
              src: `${cdnBase}images/banners/community.jpg`,
              mobilesrc: `${cdnBase}images/banners/community.jpg`
            },
            heading: 'Tech Deck',
            headingbig: 'Community',
            copy: 'Bekijk de ongelooflijke Tech Deck-community',
            link: ''
          }
        ],
        bigbutton:[
          {
            bg:{
              type: 'img',
              src: `${cdnBase}images/events/events_bg.jpg`,
              mobilesrc: `${cdnBase}images/events/events_bg.jpg`
            },
            heading: 'Tech Deck',
            headingbig: 'Events',
            copy: 'Get a recap of all our 2016 events!',
            link: '/nl_nl/events',
            class:'eventsbtn'
          },
          {
            bg:{
              type: 'vid',
              src: `${cdnBase}videos/videos.mp4`,
              mobilesrc: `${cdnBase}images/banners/community.jpg`
            },
            heading: 'Vorige',
            headingbig: 'Vidz',
            copy: '#SKATEORSCOOT SERIES - SKATE CITY - Je hebt erop gestemd... Skate City is het! ',
            link: '/nl_nl/videos',
            class:'vidz'
          }
        ],
        social:{
          header: "Social",
          copy: "Kijk behind the scenes bij onze eerdere evenementen en krijg een sneak peak van nieuwe boardafbeeldingen en speciale swag exclusief voor Tech Deck-fans. ",
          img: [
            {
              class: "instagram",
              url: "https://www.instagram.com/techdeck/",
              img: `${cdnBase}images/icons/instagram.svg`
            },
            {
              class: "youtube",
              url: "https://www.youtube.com/user/TechDeckVideos",
              img: `${cdnBase}images/icons/youtube.svg`
            },
            {
              class: "facebook",
              url: "https://www.facebook.com/pages/Tech-Deck/65095093608",
              img: `${cdnBase}images/icons/facebook.svg`
            }
          ]
        }
      },
      
      slides: [
        {
          heading: 'Denk je ',
          subheading: 'dat je trucjes kent?',
          cta: 'bekijk de nieuwste ramp',
          link: '/nl_nl/toys',
          img: `${cdnBase}images/slides/2.png`,
          img2x: `${cdnBase}images/slides/2.png`
        }
      ],
      retailers_header: 'Waar te koop',
      retailers: [
        {
          name: 'BEEUSAERT BRAET',
          href: 'https://shop.braet.net/nl-BE',
          img: `${cdnBase}images/retailers/nl_nl/beeusaert_braet.jpg`
        },
        {
          name: 'BROZE',
          href: 'https://www.broze.be/web/BrozeWSite.nsf/ArticleByRef/SPN6028845?OpenDocument',
          img: `${cdnBase}images/retailers/nl_nl/Broze+logo+FR.jpg`
        },
        {
          name: 'Cora',
          href: 'http://www.walmart.com/search/?cat_id=&query=tech+deck',
          img: `${cdnBase}images/retailers/nl_nl/CORA.png`
        },
        {
          name: 'Dreamland',
          href: 'https://www.dreamland.be/e/SearchDisplay?categoryId=&storeId=13102&catalogId=15501&langId=-11&sType=SimpleSearch&resultCatEntryType=2&showResultsPage=true&searchSource=Q&pageView=&beginIndex=0&pageSize=48&searchTerm=tech+deck&#facet:&productBeginIndex:0&facetLimit:&orderBy:&pageView:grid&minPrice:&maxPrice:&pageSize:&',
          img: `${cdnBase}images/retailers/nl_nl/landing-logo-dreamland.jpg`
        },
        {
          name: 'FUN BELGIUM NV',
          href: 'https://www.fun.be/search/?q=tech+deck',
          img: `${cdnBase}images/retailers/nl_nl/FUN.BE.png`
        },
        {
          name: 'TRAFIC',
          href: '',
          img: `${cdnBase}images/retailers/nl_nl/trafic.png`
        },
        {
          name: 'TOP 1 TOYS',
          href: 'https://www.top1toys.nl/tech-deck-skate-shop-bonus-pack-assorti-861-8845?sqr=tech%20deck&',
          img: `${cdnBase}images/retailers/nl_nl/T1T+Lang+FC+geel+vlak.jpg`
        },
        {
          name: 'TOYCHAMP',
          href: 'https://www.toychamp.nl/zoeken/producten?q=tech+deck',
          img: `${cdnBase}images/retailers/nl_nl/toychamp_champy.jpg`
        },
        {
          name: 'TOYS & TOYS CVBA',
          href: 'https://www.toystoys.be/catalogus/spin-master-6028845-6028845-tech-deck-sk8shop-bonus-pack-fingerboard',
          img: `${cdnBase}images/retailers/nl_nl/ATOYS%26TOYS+aangepast.svg`
        },
        {
          name: 'Eurojouets Wavre',
          href: 'https://www.joueclub.fr/contenu/resultat-de-recherche-produits.html?searchText=tech%20deck',
          img: `${cdnBase}images/retailers/target.png`
        },
        {
          name: 'Speelgoedwinkel.nl B.V.',
          href: 'https://www.speelgoedwinkel.nl/search/merk/tech-deck?q=tech+deck',
          img: `${cdnBase}images/retailers/nl_nl/speelgoedwinkel+logo.png`
        },
        {
          name: 'Cactus',
          href: '',
          img: `${cdnBase}images/retailers/nl_nl/Cactus_logo.svg`
        },
        {
          name: 'Bol.com',
          href: 'https://www.bol.com/nl/nl/b/tech-deck/3993399/',
          img: `${cdnBase}images/retailers/nl_nl/bolcom_logo_blauw_rgb.jpg`
        },
        {
          name: 'Amazon.nl',
          href: 'https://www.amazon.nl/s?rh=n%3A16242743031%2Cp_4%3ATech+Deck&ref=bl_dp_s_web_16242743031',
          img: `${cdnBase}images/retailers/nl_nl/Amazon.nl.jpg`
        },
      ]
    },
    collection: {
      title: 'Tech Deck collecite',
      nextPage: 'Volgende pagina',
      prevPage: 'Vorige pagina',
      all: 'Alles',
      wantIt: 'Wil het',
      haveIt: 'Heb het',
      flipIt: 'Feiten',
      brand: 'Merk',
      year: 'Jaar',
      skater: 'Skater',
      series: 'Series',
      showing: 'Showing',
      filters: [
        'brand',
        'year',
        'skater',
        'series'
      ]
    },
    support: {
      title: 'Ondersteuning',
      subTitle: 'Ondersteuning'
    },
    toys: {
      title: 'Speelgoed',
      subTitle: 'Speelgoed',
      learn: 'leer',
      more: 'meer'
    },
    toy: {
      title: 'Speelgoed',
      subTitle: 'Speelgoed',
      backToToys: 'Terug naar Speelgoed',
      features: 'Kenmerken',
      image: 'Image',
      contents: 'Inhoud',
      whereToBuy: 'Waar te koop',
      instructions: 'Instructies',
      videoInstructions: 'Video instructies',
      buyNow: 'Koop nu',
      retailers: 'Winkeliers'
    },
    downloads: {
      title: 'Downloads',
      dldownload: 'Download',
      bestResults: 'For best results, print your Gifeez disks on thicker paper',
      rightDisks: `${cdnBase}downloads/right_disk_compress.png`,
      rightDisks_Alt: 'smiley and car disks',
      leftDisks: `${cdnBase}downloads/left_disk2_compress.png`,
      leftDisks_Alt: 'robot and eggs disks',
      catList: ['all', 'animation', 'art'],
      animationImg: `${cdnBase}downloads/animation_decal.png`,
      artImg: `${cdnBase}downloads/art_decal.png`,
      diskDownloadsList: [
        {
          title:'Drop 1',
          poster: `${cdnBase}images/Thumb-Drop1.jpg`,
          downloadPDF:`${cdnBase}2021+Drop+1+print.pdf`,
          categories: ['all','art']
        },
        {
          title:'Drop 2',
          poster: `${cdnBase}images/Thumb-Drop2.jpg`,
          downloadPDF:`${cdnBase}2021+Drop+2+print.pdf`,
          categories: ['all','animation'],
        },
        {
          title:'Drop 3',
          poster: `${cdnBase}images/Thumb-Drop3.jpg`,
          downloadPDF:`${cdnBase}2021+Drop+3+print.pdf`,
          categories: ['all','animation'],
        }
      ]
    },
    videos: {
      title: 'Videos',
      subTitle: 'Videos',
      playVideo: 'Speel video',
      playButton_Alt: 'play button',
      defaultVideoIndex: 0,
      categories: 'Categorieën',
      catList: ['all', 'tips and tricks', 'skater vs', 'commercials', 'hall of fame', 'events'],
      videoList: [
        {
          title:'TRICK TUESDAY - VARIAL HEELFLIP',
          video:'Y7xzS0DtQu4',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - BACKSIDE FLIP',
          video:'WIRLEQs8FtM',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - BIGGER SPIN FLIP',
          video:'wBXhz-A9oaA',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - 540 FLIP',
          video:'zIRPkCrRdqk',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - 360 DOUBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - 360 DOUBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - 360 DOUBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - 360 DOUBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - 360 DOUBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TRICK TUESDAY - NOLLIE 360 FLIP',
          video:'4-MXtHedIXU',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECK DECK - HOW TO OLLIE',
          video:'Y7xzS0DtQu4',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'MASTER TECH DECK FINGERBOARDER',
          video:'EmrYO3Sj0M0',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #1: SHOVE IT',
          video: 'NPkKwpsGUc',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #2: OLLIE / NOLLIE',
          video:'lgeO_y4RGIE',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #3: SLIDES',
          video:'DLBqbuUPiuo',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #4: GRINDS',
          video:'aMQNMifd7AE',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #5: FLIPS',
          video:'rZUzb302KW8',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #6: RAMP TRICKS',
          video:'_UIalVhh_HQ',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK VIDEO #7: PUTTING IT ALL TOGETHER',
          video:'Wbb7GWpt_MU',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TUTORIALS: GETTING STARTED',
          video:'Rp1VRRZGYi0',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TUTORIALS: INTERMEDIATE STREET TRICKS',
          video:'exi1QzBaJR4',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TUTORIALS: BASIC STREET TRICKS',
          video:'XTm0JV3RrX8',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TUTORIALS: PLAYING S-K-A-T-E',
          video:'oLO8VOI2a64',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TUTORIALS: INTERMEDIATE VERT TRICKS',
          video:'HblCzK70ZSw',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TUTORIALS: ADVANCED VERT TRICKS',
          video:'1fJlTyUS7mM',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK TAPE - FLIP TRICKS',
          video:'B7DVCA-T1TQ',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'TECH DECK TRICK TAPE - GETTING STARTED',
          video:'zLjdg-5sCyA',
          type: 'youtube',
          categories: ['all','tips and tricks']
        },
        {
          title:'Skater Vs… Cheeseburger',
          video:'eJ1kCjQHPTg',
          type: 'youtube',
          categories: ['all','skate vs']
        },
        {
          title:'Skater Vs... Human Skeeball',
          video:'k80JZ_I_uRs',
          type: 'youtube',
          categories: ['all','skater vs']
        },
        {
          title:'SKATER VS... PIÑATA',
          video:'Z0sF96aj-So',
          type: 'youtube',
          categories: ['all','skater vs']
        },
        {
          title:'SKATER VS... PIÑATA',
          video:'Z0sF96aj-So',
          type: 'youtube',
          categories: ['all','skater vs']
        },
        {
          title:'TECH DECK - START SMALL GO BIG!',
          video:'AUdmc3swvcE',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:'#SKATEORSCOOT SERIES - SKATE CITY',
          video: 'sZ8Qlx72dt',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:'TECH DECK DUDES VANFORMER',
          video:'Srft3KKXdis',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:'TECH DECK COMMERCIAL: FALL 2010',
          video:'E-sgk4P95jA',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:'TECH DECK PAUL RODRIGUEZ SKATELAB COMMERCIAL',
          video:'usXjTSVq7UY',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`TECH DECK'S 2011 SKATE SHOP COLLAB BOARDS`,
          video:'kVfWcA4HEyM',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`TECH DECK SKATE AND GO TV SPOT`,
          video:'vDPp1xN94YI',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`TECH DECK TONY HAWK BIG RAMPS`,
          video:'rAj9VYXVs1g',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`BAG OF TRICKS`,
          video:'ybK00UKz1DE',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`TECH DECK TRICK TAPE - GETTING STARTED`,
          video:'zLjdg-5sCyA',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`TECH DECK LONGBOARDS`,
          video:'bUfFVSJstQQ',
          type: 'youtube',
          categories: ['all','commercials']
        },
        {
          title:`FINGERBOARDER VS SKATER`,
          video:'DIfn_QSoF5E',
          type: 'youtube',
          categories: ['all','hall of fame']
        },
        {
          title:`BROTHER & SISTER`,
          video:'TX1aMdIqx2o',
          type: 'youtube',
          categories: ['all','hall of fame']
        },
        {
          title:`FINGERBOARD SPIRIT`,
          video:'2prsTK1X4Iw',
          type: 'youtube',
          categories: ['all','hall of fame']
        },
        {
          title:`MASTER SKATER`,
          video:'NF26LZHyf08',
          type: 'youtube',
          categories: ['all','hall of fame']
        },
        {
          title:`NEVER STOP SKATING`,
          video:'zQyijf7tdUY',
          type: 'youtube',
          categories: ['all','hall of fame']
        },
        {
          title:`POPS`,
          video:'fobZUTlbTEA',
          type: 'youtube',
          categories: ['all','hall of fame']
        },
        {
          title:`TECH DECK AT DEW TOUR`,
          video:'u-14TXK1LjM',
          type: 'youtube',
          categories: ['all','events']
        }
      ]
    },
    events: {
      title: 'events',
      copy: `Its been a crazy year but things are looking up! Check out some of the awesome events we’ll be at this year. We will announce more details soon so keep checking back!
      `,
      upcomingevents: [
        
      ],
      upcomingheader: 'Komende evenementen',
      ctaimage: 'images',
      ctalearnmore: 'ontdek meer',
      pastheader: 'Vorige evenementen',
      pastevents: [
        {
          thumb: `${cdnBase}images/events/dew_tour2021.png`,
          title: 'Dew Tour Details',
          copy: `Dew Tour is an innovative contest series and content platform that brings together the world’s best skateboarders. The event includes World Skate sanctioned men’s and women’s skateboarding Street and Park competitions, serving as the only U.S.-based global Olympic skateboard qualifying events for 2021.`,
          images: [],
          ctalink: 'https://www.dewtour.com/skate/',
          date: {
            month: 'May',
            day: '20th - 23rd',
            time: 'All Day',
            location: 'Des Moines, Iowa',
            year: 2021
          }
        },
        // {
        //   thumb: `${cdnBase}images/events/exposure.jpg`,
        //   title: 'Exposure Skate Event',
        //   copy: 'Exposure creates opportunities for female skateboarders and raises money for survivors of domestic violence.',
        //   images: [],
        //   ctalink: 'http://exposureskate.org/',
        //   date: {
        //     month: 'November',
        //     day: '5th',
        //     time: 'All Day',
        //     year: 2017
        //   }
        // },
     
        // {
        //   thumb: `${cdnBase}images/events/sls_2.jpg`,
        //   title: 'SLS Nike Super Crown World Tour Championships',
        //   copy: 'Come visit us at the Tech Deck booth and show us your best Fingerboarding skills!',
        //   images: [],
        //   ctalink: 'http://streetleague.com/?event=sls-nike-sb-super-crown-world-championship',
        //   date: {
        //     month: 'October',
        //     day: '2nd',
        //     time: '4:00 PM',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/sls_1.jpg`,
        //   title: 'SLS Nike SB World Tour',
        //   copy: 'Come visit us at the Tech Deck booth and show us your best Fingerboarding skills!',
        //   images: [],
        //   ctalink: 'http://streetleague.com/?event=sls-nike-sb-world-tour-new-jersey',
        //   date: {
        //     month: 'August',
        //     day: '28th',
        //     time: '12:15 PM',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/dew_tour.jpg`,
        //   title: 'Dew Tour',
        //   copy: 'Dew Tour is an innovative contest series and content platform that brings together the world’s best skateboarders.',
        //   images: [],
        //   ctalink: 'http://www.dewtour.com/skate/',
        //   date: {
        //     month: 'July',
        //     day: '22-24th',
        //     time: 'All Day',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/clash-at-clairmont.jpg`,
        //   title: 'Clash at Clairmont',
        //   copy: 'The event, a celebration of the newly acquired ramp and park renovations was an opportunity to create a fundraiser that ...',
        //   images: [],
        //   ctalink: 'http://www.clashatclairemont.com/Clash7/',
        //   date: {
        //     month: 'June',
        //     day: '25th',
        //     time: '11am - 6:30pm',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/go-skateboarding-day.jpg`,
        //   title: 'GO Skateboarding Day',
        //   copy: 'On June 21 skateboarders around the globe will celebrate the pure exhilaration, creativity, and spirit of one of the mos...',
        //   images: [],
        //   ctalink: 'http://theiasc.org/go-skateboarding-day/',
        //   date: {
        //     month: 'June',
        //     day: '21st',
        //     time: 'All Day',
        //     year: 2016
        //   }
        // }
      ]
    },
    nomatch: {
      title: 'Pagina niet gevonden'
    },
    privacy: {
      title: 'Privacybeleid',
      subTitle: 'Privacybeleid',
      link: 'https://www.spinmaster.com/en-US/legal/privacy-policy'
    },
    terms: {
      title: 'Servicevoorwaarden',
      subTitle: 'Servicevoorwaarden',
      link: 'https://www.spinmaster.com/en-US/legal/terms-of-use'
    }
  }
}