import {cdnBase} from '../../const';

export default {
  mx_mx: {
    langKeys: {
      en_us: 'Inglés (EUA)',
      mx_mx: 'Mexico',
      nl_nl: "Netherlands"
    },
    interstitial: {
      heading: 'Estás dejando este sitio',
      copy: 'Al hacer clic en "continuar", saldrás de este sitio web y serás redireccionado a otro sitio web. Recuerda, tienen diferentes términos y políticas de privacidad. ¡Esperamos verte pronto de nuevo! Niños, obtengan el permiso de sus padres antes de visitar otros sitios y nunca compartan información personal sobre ustedes, incluido tu nombre completo, dirección y número de teléfono, y permite que tus padres o tutores se encarguen de cualquier transacción.',
      continue: 'Continuar',
      cancel: 'Cancelar'
    },
    brand: 'Tech Deck',
    applinks: {
      apple: 'Link a la app en Apple', 
      google: 'Link a la app en Google'
    },
    chooseRegion: 'Selecciona un idioma',
    loading: 'Cargando',
    select: 'Selecciona',
    clearAll: 'Eliminar todo',
    filters: 'Filtros',
    details: 'Detalles',
    warnings: {
      enableIframes: 'Debes habilitar iFrames para ver este video'
    },
    helmet: {
      siteTitle: ' | Tech Deck '
    },
    navigation: {
      mainmenu: [
        {
          type: 'internal',
          href: '/toys',
          display: 'Productos'
        },
        // It has been requested for this menu item to be removed until an update for the collections page is provided at a later date
        // {
        //   type: 'internal',
        //   href: '/collection',
        //   display: 'Collection'
        // },
        {
          type: 'external',
          href: 'https://www.youtube.com/user/TechDeckVideos',
          display: 'Videos'
        },
        {
          type: 'internal',
          href: '/downloads',
          display: 'Descargables'
        },
        //{
        //  type: 'internal',
        //  href: '/events',
        //  display: 'Events'
        //},
        {
          type: 'internal_hash',
          href: '/#social',
          display: 'Social'
        },
        {
          type: 'internal_hash',
          href: '/#wheretobuy',
          display: 'Dónde Comprar'
        },
        {
          type: 'external',
          href: 'https://spinmastersupport.helpshift.com/a/tech-deck/?p=web',
          display: 'Ayuda'
        }
      ],
      logo: "https://techdeck2019.s3.amazonaws.com/images/logos/td_logo_updated.png"
    },
    footer: {
      brand: 'Spin Master',
      contactUs: "Contáctanos",
      privacy: 'Política de Privacidad',
      terms: 'Términos de Servicio',
      support: 'Ayuda',
      copy: 'Spin Master Ltd. Todos los derehos reservados.',
      contactLink: 'https://www.spinmaster.com/contact-us.php?userLoc=us&display=customer_service_info',
      privacyLink: 'https://www.spinmaster.com/privacy-policy.php?userLoc=us',
      termsLink: 'https://www.spinmaster.com/terms-of-service.php?userLoc=us',
      supportLink: 'https://www.spinmaster.com/support.php?userLoc=us'
    },
    home: {
      title: 'Inicio',
      whereToBuy: 'Dónde Comprar',
      sections: {
        collection:{
          heading: "This is Awesome Bloss´ems, not Tech Deck",
          subheading:[ "Colección","Serie 1"],
          button: "Ver Colección Completa"
        },
        smallbutton:[
          {
            class:'academy',
            bg:{
              type: 'vid',
              src: `${cdnBase}videos/F21_June_TechDeck_Downloads_720x480.mp4`,
              mobilesrc: `${cdnBase}images/banners/holder.png`
            },
            heading: 'Tech Deck',
            headingbig: 'Lanzamientos 2021',
            copy: 'Mira lo que viene en 2021. ¿Puedes coleccionar todos?',
            link: '/mx_mx/downloads'
          },
          {
            class:'collection',
            bg:{
              type: 'vid',
              src: `${cdnBase}videos/hallof.mp4`,
              mobilesrc: `${cdnBase}images/banners/holder.png`
            },
            heading: 'Contruye tu',
            headingbig: 'Colección',
            copy: '¡Contruye tu colección de Tech Deck! ¡Empieza con poco y termina en grande!',
            link: '/mx_mx/toys'
          },
          {
            class:'community',
            bg:{
              type: 'img',
              src: `${cdnBase}images/banners/community.jpg`,
              mobilesrc: `${cdnBase}images/banners/community.jpg`
            },
            heading: 'Tech Deck',
            headingbig: 'Comunidad',
            copy: 'Conoce la increíble comunidad de Tech Deck',
            link: ''
          }
        ],
        bigbutton:[
          {
            bg:{
              type: 'vid',
              src: `${cdnBase}techupdates/Assets+for+Dev+2/+LilJah-Makingof-VIdeo-Loop.mp4`,
              mobilesrc: `${cdnBase}techupdates/Assets+for+Dev+2/LilJa-VideoLoadingFrame.jpg`
            },
            heading: 'Anterior',
            headingbig: 'Videos',
            copy: '#SKATEORSCOOT SERIE - SKATE CITY - ¡Ustedes votaron… será Skate City!',
            link: '/mx_mx/videos',
            class:'vidz'
          },
          {
            bg:{
              type: 'vid',
              src: `${cdnBase}techupdates/Assets+for+Dev+2/X-Connect-Video-Loop.mp4`,
              mobilesrc: `${cdnBase}techupdates/Assets+for+Dev+2/x-Connect-VideoLoadingFrame.jpg`
            },
            heading: 'Anterior',
            headingbig: 'Videos',
            copy: '#SKATEORSCOOT SERIE - SKATE CITY - ¡Ustedes votaron… será Skate City!',
            link: '/mx_mx/videos',
            class:'vidz'
          }
        ],
        social:{
          header: "Social",
          copy: "Echa un vistazo detrás de escena de nuestros eventos pasados, echa un vistazo a los nuevos gráficos de las patinetas y un estilo exclusivo para los fanáticos de Tech Deck.",
          img: [
            {
              class: "instagram",
              url: "https://www.instagram.com/techdeck/",
              img: `${cdnBase}images/icons/instagram.svg`
            },
            {
              class: "youtube",
              url: "https://www.youtube.com/user/TechDeckVideos",
              img: `${cdnBase}images/icons/youtube.svg`
            },
            {
              class: "facebook",
              url: "https://www.facebook.com/pages/Tech-Deck/65095093608",
              img: `${cdnBase}images/icons/facebook.svg`
            }
          ]
        }
      },
      
      slides: [
        {
          heading: 'Piensa a tu manera',
          subheading: '¿Tienes trucos?',
          cta: 'ver la rampa más nueva',
          link: '/mx_mx/toys',
          img: `${cdnBase}techupdates/Assets+for+Dev+2/S22_TechDeck_HeroBanner-Ramps.png`,
          img2x: `${cdnBase}techupdates/Assets+for+Dev+2/S22_TechDeck_HeroBanner-Ramps.png`
        }
      ],
      retailers_header: 'Dónde Comprar',
      retailers: [
        {
          name: 'Amazon',
          href: 'https://www.amazon.com.mx/s?k=tech+deck&i=toys&__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&ref=nb_sb_noss_2%29',
          img: `${cdnBase}images/retailers/amazon.png`
        },
        // {
        //   name: 'Target',
        //   href: 'http://www.target.com/s?category=0%7CAll%7Cmatchallpartial%7Call+categories&searchTerm=tech%20deck',
        //   img: `${cdnBase}images/retailers/target.png`
        // }
        /*
        {
          name: 'Walmart',
          href: 'http://www.walmart.com/search/?cat_id=&query=tech+deck',
          img: `${cdnBase}images/retailers/walmart.png`
        },
        {
          name: 'Kmart',
          href: 'http://www.kmart.com/search=tech%20deck',
          img: `${cdnBase}images/retailers/kmart.png`
        }*/
      ]
    },
    collection: {
      title: 'Colección de Tech Deck',
      nextPage: 'Página Siguiente',
      prevPage: 'Página Anterior',
      all: 'Todos',
      wantIt: 'Quiero',
      haveIt: 'Tengo',
      flipIt: 'Hechos',
      brand: 'Marca',
      year: 'Año',
      skater: 'Patinador',
      series: 'Series',
      showing: 'Demostración',
      filters: [
        'marca',
        'año',
        'patinador',
        'series'
      ]
    },
    support: {
      title: 'Ayuda',
      subTitle: 'Ayuda'
    },
    toys: {
      title: 'Juguetes',
      subTitle: 'Juguetes',
      learn: 'conocer',
      more: 'más'
    },
    toy: {
      title: 'Juguete',
      subTitle: 'Juguete',
      backToToys: 'Volver a Juguetes',
      features: 'Características',
      image: 'Imagen',
      contents: 'Contenido',
      whereToBuy: 'Dónde Comprar',
      instructions: 'Instrucciones',
      videoInstructions: 'Video de Instrucciones',
      buyNow: 'Compra Ahora',
      retailers: 'Distribuidores'
    },
    downloads: {
      title: 'Descargables',
      dldownload: 'Descargables',
      bestResults: 'For best results, print your Gifeez disks on thicker paper',
      rightDisks: `${cdnBase}downloads/right_disk_compress.png`,
      rightDisks_Alt: 'smiley and car disks',
      leftDisks: `${cdnBase}downloads/left_disk2_compress.png`,
      leftDisks_Alt: 'robot and eggs disks',
      catList: ['all', 'animation', 'art'],
      animationImg: `${cdnBase}downloads/animation_decal.png`,
      artImg: `${cdnBase}downloads/art_decal.png`,
      diskDownloadsList: [
        {
          title:'Drop 1',
          poster: `${cdnBase}images/Thumb-Drop1.jpg`,
          downloadPDF:`${cdnBase}2021+Drop+1+print.pdf`,
          categories: ['all','art']
        },
        {
          title:'Drop 2',
          poster: `${cdnBase}images/Thumb-Drop2.jpg`,
          downloadPDF:`${cdnBase}2021+Drop+2+print.pdf`,
          categories: ['all','animation'],
        },
        {
          title:'Drop 3',
          poster: `${cdnBase}images/Thumb-Drop3.jpg`,
          downloadPDF:`${cdnBase}2021+Drop+3+print.pdf`,
          categories: ['all','animation'],
        }
      ]
    },
    videos: {
      title: 'Videos',
      subTitle: 'Videos',
      playVideo: 'Reproducir Video',
      playButton_Alt: 'Botón de Reproducir',
      defaultVideoIndex: 0,
      categories: 'Categorías',
      catList: ['todos','consejos y trucos','patinador vs',
      'comerciales','salón de la fama','eventos'],
      videoList: [
        {
          title:'MARTES DE TRUCOS - VARIAL HEELFLIP',
          video:'Y7xzS0DtQu4',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - FLIP TRASERO',
          video:'WIRLEQs8FtM',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - GIRO MÁS GRANDE',
          video:'wBXhz-A9oaA',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - 360 DOBLE FLIP',
          video:'zIRPkCrRdqk',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - 360 DOBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - 360 DOBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - 360 DOBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - 360 DOBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - 360 DOBLE FLIP',
          video:'YTtRO2JO4zk',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'MARTES DE TRUCOS - NOLLIE 360 FLIP',
          video:'4-MXtHedIXU',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TECH DECK - ¿CÓMO HACER UN OLLIE?',
          video:'Y7xzS0DtQu4',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'EXPERTO EN HACER TRUCOS CON LOS DEDOS CON LAS PATINETAS TECK DECK',
          video:'EmrYO3Sj0M0',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #1: EMPÚJALO',
          video: 'NPkKwpsGUc',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #2: OLLIE / NOLLIE',
          video:'lgeO_y4RGIE',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #3: RAMPAS',
          video:'DLBqbuUPiuo',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #4: SALTOS',
          video:'aMQNMifd7AE',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #5: FLIPS',
          video:'rZUzb302KW8',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #6: TRUCOS EN RAMPAS',
          video:'_UIalVhh_HQ',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TRUCO DE TECH DECK #7: JUNTANDO TODO',
          video:'Wbb7GWpt_MU',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TUTORIALES DE TECH DECK: EMPEZANDO',
          video:'Rp1VRRZGYi0',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TUTORIALES DE TECH DECK: TRUCOS CALLEJEROS INTERMEDIOS',
          video:'exi1QzBaJR4',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TUTORIALES DE TECK DECK: TRUCOS CALLEJEROS BÁSICOS',
          video:'XTm0JV3RrX8',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TUTORIALES DE TECK DECK: JUGANDO A P-A-T-I-N-A-R',
          video:'oLO8VOI2a64',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TUTORIALES DE TECH DECK: TRUCOS VERTICALES INTERMEDIOS',
          video:'HblCzK70ZSw',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'TUTORIALES DE TECH DECK: TRUCOS VERTICALES AVANZADOS',
          video:'1fJlTyUS7mM',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'CINTA DE TRUCOS DE TECH DECK: FLIP TRICKS',
          video:'B7DVCA-T1TQ',
          type: 'youtube',
          categories: ['todos','consejos y trucos']
        },
        {
          title:'CINTA DE TRUCOS DE TECH DECK - COMENZANDO',
          video:'zLjdg-5sCyA',
          type: 'youtube',
          categories: ['all','consejos y trucos']
        },
        {
          title:'Skater vs… Hamburguesa con queso',
          video:'eJ1kCjQHPTg',
          type: 'youtube',
          categories: ['all','patinar vs']
        },
        {
          title:'Skater vs… Bola de Skii Humana',
          video:'k80JZ_I_uRs',
          type: 'youtube',
          categories: ['todos','patinar vs']
        },
        {
          title:'SKATER VS... PIÑATA',
          video:'Z0sF96aj-So',
          type: 'youtube',
          categories: ['todos','patinar vs']
        },
        {
          title:'SKATER VS… PIÑATA',
          video:'Z0sF96aj-So',
          type: 'youtube',
          categories: ['todos','patinar vs']
        },
        {
          title:'TECH DECK - ¡EMPIEZA CON POCO, TERMINA EN GRANDE!',
          video:'AUdmc3swvcE',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:'#SKATEORSCOOT SERIE - SKATE CITY',
          video: 'sZ8Qlx72dt',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:'CHICOS TECH DECK VANFORMER',
          video:'Srft3KKXdis',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:'COMERCIAL DE TECH DECK: FALL 2010',
          video:'E-sgk4P95jA',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:'COMERCIAL DE TECH DECK LABORATORIO DE SKATE PAUL RODRIGUEZ',
          video:'usXjTSVq7UY',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`COMPRA PATINETAS DE COLABORACIONES DE TECH DECK 2011`,
          video:'kVfWcA4HEyM',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`COMERCIAL DE TV DE TECH DECK`,
          video:'vDPp1xN94YI',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`RAMPAS GRANDES DE TECH DECK DE TONY HAWK`,
          video:'rAj9VYXVs1g',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`BOLSA DE TRUCOS`,
          video:'ybK00UKz1DE',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`CINTA DE TRUCOS DE TECH DECK - COMENZANDO`,
          video:'zLjdg-5sCyA',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`PATINETAS LARGAS TECH DECK`,
          video:'bUfFVSJstQQ',
          type: 'youtube',
          categories: ['todos','comerciales']
        },
        {
          title:`PATINAR CON LOS DEDOS VS SKATER`,
          video:'DIfn_QSoF5E',
          type: 'youtube',
          categories: ['todos', 'salón de la fama']
        },
        {
          title:`HERMANOS`,
          video:'TX1aMdIqx2o',
          type: 'youtube',
          categories: ['todos', 'salón de la fama']
        },
        {
          title:`ESPÍRITU DE PATINAR CON LOS DEDOS`,
          video:'2prsTK1X4Iw',
          type: 'youtube',
          categories: ['todos', 'salón de la fama']
        },
        {
          title:`EXPERTO EN SKATE`,
          video:'NF26LZHyf08',
          type: 'youtube',
          categories: ['todos', 'salón de la fama']
        },
        {
          title:`NUNCA PARES DE PATINAR`,
          video:'zQyijf7tdUY',
          type: 'youtube',
          categories: ['todos', 'salón de la fama']
        },
        {
          title:`POPS`,
          video:'fobZUTlbTEA',
          type: 'youtube',
          categories: ['todos', 'salón de la fama']
        },
        {
          title:`TECH DECK EN EL TOUR DEW`,
          video:'u-14TXK1LjM',
          type: 'youtube',
          categories: ['todos','eventos']
        }
      ]
    },
    events: {
      title: 'eventos',
      copy: `¡Fans de Tech Deck! Miren dónde estuvimos el año pasado. ¿Te perdiste alguno de los eventos? No te preocupe, nos embarcaremos en una nueva gira de 2017 y pronto anunciaremos las fechas.`,
      upcomingevents: [
        
      ],
      upcomingheader: 'Eventos Próximos',
      ctaimage: 'imágenes',
      ctalearnmore: 'conoce más',
      pastheader: 'Eventos Pasados',
      pastevents: [
        {
          thumb: `${cdnBase}images/events/dew_tour2021.png`,
          title: 'Tour Dew',
          copy: `Dew Tour es una serie de concursos innovadores y una plataforma de contenido que reúne a los mejores patinadores del mundo.`,
          images: [],
          ctalink: 'https://www.dewtour.com/skate/',
          date: {
            month: 'Julio',
            day: '22nd - 24th',
            time: 'Todo el día',
            location: 'Des Moines, Iowa',
            year: 2016
          }
        },
        // {
        //   thumb: `${cdnBase}images/events/exposure.jpg`,
        //   title: 'Exposure Skate Event',
        //   copy: 'Exposure creates opportunities for female skateboarders and raises money for survivors of domestic violence.',
        //   images: [],
        //   ctalink: 'http://exposureskate.org/',
        //   date: {
        //     month: 'November',
        //     day: '5th',
        //     time: 'All Day',
        //     year: 2017
        //   }
        // },
     
        // {
        //   thumb: `${cdnBase}images/events/sls_2.jpg`,
        //   title: 'SLS Nike Super Crown World Tour Championships',
        //   copy: 'Come visit us at the Tech Deck booth and show us your best Fingerboarding skills!',
        //   images: [],
        //   ctalink: 'http://streetleague.com/?event=sls-nike-sb-super-crown-world-championship',
        //   date: {
        //     month: 'October',
        //     day: '2nd',
        //     time: '4:00 PM',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/sls_1.jpg`,
        //   title: 'SLS Nike SB World Tour',
        //   copy: 'Come visit us at the Tech Deck booth and show us your best Fingerboarding skills!',
        //   images: [],
        //   ctalink: 'http://streetleague.com/?event=sls-nike-sb-world-tour-new-jersey',
        //   date: {
        //     month: 'August',
        //     day: '28th',
        //     time: '12:15 PM',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/dew_tour.jpg`,
        //   title: 'Dew Tour',
        //   copy: 'Dew Tour is an innovative contest series and content platform that brings together the world’s best skateboarders.',
        //   images: [],
        //   ctalink: 'http://www.dewtour.com/skate/',
        //   date: {
        //     month: 'July',
        //     day: '22-24th',
        //     time: 'All Day',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/clash-at-clairmont.jpg`,
        //   title: 'Clash at Clairmont',
        //   copy: 'The event, a celebration of the newly acquired ramp and park renovations was an opportunity to create a fundraiser that ...',
        //   images: [],
        //   ctalink: 'http://www.clashatclairemont.com/Clash7/',
        //   date: {
        //     month: 'June',
        //     day: '25th',
        //     time: '11am - 6:30pm',
        //     year: 2016
        //   }
        // },
        // {
        //   thumb: `${cdnBase}images/events/go-skateboarding-day.jpg`,
        //   title: 'GO Skateboarding Day',
        //   copy: 'On June 21 skateboarders around the globe will celebrate the pure exhilaration, creativity, and spirit of one of the mos...',
        //   images: [],
        //   ctalink: 'http://theiasc.org/go-skateboarding-day/',
        //   date: {
        //     month: 'June',
        //     day: '21st',
        //     time: 'All Day',
        //     year: 2016
        //   }
        // }
      ]
    },
    nomatch: {
      title: 'Página no encontrada'
    },
    privacy: {
      title: 'Política de Privacidad',
      subTitle: 'Política de Privacidad',
      privacyLink: 'https://www.spinmaster.com/en-US/legal/privacy-policy'
    },
    terms: {
      title: 'Términos de Servicio',
      subTitle: 'Términos de Servicio',
      termsLink: 'https://www.spinmaster.com/en-US/legal/terms-of-use'
    }
  }
}