import LocalizedStrings from 'react-localization'

/* eslint-disable camelcase */
import en_us from './en_us';
import mx_mx from './mx_mx';
import nl_nl from './nl_nl';

export const lang = new LocalizedStrings({
  ...en_us,
  ...mx_mx,
  ...nl_nl
})

